import React, { InputHTMLAttributes } from "react";
import CircularProgress from "@mui/material/CircularProgress";

interface LoderButtonProps extends InputHTMLAttributes<HTMLInputElement> {
  disabled?: boolean;
  loader?: boolean;
  label?: string | number;
  onClick?: () => void;
  type: "button" | "submit";
}

const Button: React.FC<LoderButtonProps> = ({
  disabled,
  loader,
  label,
  style,
  onClick,
  ...props
}) => {
  return (
    <button
      onClick={onClick}
      disabled={loader}
      className={props.className}
      style={style}
      type={props.type}
    >
      {loader && (
        <CircularProgress
          size={16}
          style={{
            color: "#fff",
            marginRight: 10,
          }}
        />
      )}
      {label}
    </button>
  );
};
export default Button;
