import dayjs from "dayjs";
import { Product } from "../types";

export const mapBody = (
  data: any,
  guid: string | undefined,
  id: number | undefined,
  registration_code: string | undefined
) => {
  const mappedValues = {
    guid,
    id,
    registration_code,
    customer_first_name: data.firstName,
    customer_last_name: data.lastName,
    customer_email: data.email,
    invoice_number: data.invoiceNumber,
    contract_purchase_date: data.planPurchaseDate
      ? dayjs(data.planPurchaseDate).format("YYYY-MM-DD")
      : "",
    product_line_items_delivery_date_of_product: data.deliveryDateOfProduct
      ? dayjs(data.deliveryDateOfProduct).format("YYYY-MM-DD")
      : "",
    product_line_items_purchase_date_of_product: data.planPurchaseDate
      ? dayjs(data.planPurchaseDate).format("YYYY-MM-DD")
      : null,
    contract_retail_price: parseFloat(data.purchasePrice),
    total_product_retail_value: parseFloat(data.invoicePrice),
    customer_street_address_1: data.street,
    customer_city: data.city,
    customer_state: data.state,
    customer_postal_code: data.zipCode,
    customer_primary_telephone: data.phone,
    customer_delivery_street_address_1: data.deliveryStreet,
    customer_delivery_city: data.deliveryCity,
    customer_delivery_state: data.deliveryState,
    customer_delivery_postal_code: data.deliveryZipCode,
    customer_file_attachment: data.fileAttachment,
    products: data.products.map((item: any) => mapProducts(item, id)),
  };

  return mappedValues;
};

const mapProducts = (data: Product, contract_id: number | undefined) => {
  return {
    manufacturer: data.manufacturer || "",
    model_number: data.model_number || "",
    serial_number: data.serial_number || "",
    product_retail_price: data.product_retail_price || 0,
    quantity: data.quantity || 0,
    pre_sold_contract: "",
  };
};
