import { RouterProvider, createBrowserRouter } from "react-router-dom";
import RegisterProtectionPlan from "./RegisterProtectionPlan/index";
import ContactUs from "./contact-us";
import Home from "./home";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/file-pre-sold-contract",
    element: <RegisterProtectionPlan />,
  },
  {
    path: "/contact-us",
    element: <ContactUs />,
  },
]);

function Pages() {
  return <RouterProvider router={router} />;
}

export default Pages;
