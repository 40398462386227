import axios from "axios";
import config from "../config";
export const axiosConsumer = axios.create({
  baseURL: config.API_URL,
  headers: {
    "x-data-source": config.XANO_DATA_SOURCE,
  },
});
export const AWSaxiosConsumer = axios.create({
  baseURL: config.SENDGRID_SEND_EMAIL_URL,
  headers: {
    "X-API-KEY": config.SENDGRID_SEND_EMAIL_API_KEY,
  },
});
