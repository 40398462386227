import { useEffect, useRef, useState } from "react";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { Values } from "../../types";
import dayjs from "dayjs";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import RegistrationForm from "../../components/RegistrationForm";
import Error404 from "./404";
import Loading from "./loading";
import { useDispatch, useSelector } from "react-redux";
import { axiosConsumer } from "../../service";
import { regiFormData } from "../../store/registration-dataSlice";
import { mapBody } from "../../helper";
import FormikForm from "../../components/RegisterProtectionPlan/step-1-form";

const validationSchema = Yup.object().shape({
  invoiceNumber: Yup.string().required("Invoice number is required"),
  planPurchaseDate: Yup.string().required("Plan purchase date is required"),
  deliveryDateOfProduct: Yup.string().required(
    "Delivery date of product is required"
  ),
  purchasePrice: Yup.string().required(
    "Protection plan purchase price is required"
  ),
  invoicePrice: Yup.string().required(
    "Total retail price paid on invoice is required"
  ),
  firstName: Yup.string()
    .matches(/^[A-Za-z\s]+$/, "Invalid firstname format")
    .required("First name is required"),
  lastName: Yup.string()
    .matches(/^[A-Za-z\s]+$/, "Invalid lastname format")
    .required("Last name is required"),
  street: Yup.string().required("Street is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string()
    .matches(/^[A-Za-z\s]+$/, "Invalid state name format")
    .required("State is required"),
  zipCode: Yup.string()
    .matches(/^\d+$/, "Zipcode must be digits")
    .required("Zipcode is required"),
  email: Yup.string()
    .email("Invalid email address format")
    .matches(
      /^[a-zA-Z0-9.+]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      "Invalid email address"
    )
    .required("Email is required"),
  phone: Yup.string().required("Phone number is required"),
  fileAttachment: Yup.mixed<File>()
    .test(
      "fileFormat",
      "Invalid file format. Only PDF, JPG, and PNG are allowed.",
      (file) => {
        const allowedFormats = ["application/pdf", "image/jpeg", "image/png"];
        return file && allowedFormats.includes(file.type || "");
      }
    )
    .required("You must select a file"),
  products: Yup.array().of(
    Yup.object().shape({
      manufacturer: Yup.string().required("Manufacturer is required"),
      model_number: Yup.string().required("Model number is required"),
      serial_number: Yup.string().required("Serial number is required"),
      product_retail_price: Yup.string()
        .matches(/^[0-9]+$/, "product price must be number")
        .required("Product price is required"),
      quantity: Yup.string()
        .matches(/^[0-9]+$/, "quantity must be number")
        .required("Quantity is required"),
    })
  ),
});

function Step1({ nextStep }: any) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const data = useSelector((state: any) => {
    return state.RegistrationData.apiData;
  });

  const [searchParams] = useSearchParams();
  const registration_code = searchParams.get("rc");
  const [loading, setLoading] = useState(!data.id);
  const [errorMassage, setErrorMassage] = useState();
  const [sameAddress, setSameAddress] = useState(false);

  const formikref = useRef<FormikProps<Values>>(null);
  const isMounted = useRef(true);

  const getContarct = async (code = registration_code) => {
    setLoading(true);
    try {
      const { data: preSoldContract } = await axiosConsumer.get(
        `/pre_sold_contract/registration_code/${code}`
      );
      dispatch(regiFormData(preSoldContract));
    } catch (error: any) {
      setErrorMassage(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!data.id && isMounted.current) {
      getContarct();
    }
    return () => {
      isMounted.current = false;
    };
  }, [data, dispatch, registration_code]);

  const handleSubmit = (values: any) => {
    const { name, type } = values.fileAttachment || {};
    const fileAttachment = {
      name,
      type,
      src:
        values.fileAttachment instanceof Blob
          ? URL.createObjectURL(values.fileAttachment)
          : values.fileAttachment.src,
    };
    dispatch(
      regiFormData({
        ...mapBody(values, data.guid, data.id, data.registration_code),
        customer_file_attachment: fileAttachment,
      })
    );
    nextStep();
  };

  const contactUs = () => {
    navigate("/contact-us");
  };

  if (loading) {
    return <Loading />;
  }

  if (!registration_code && !data?.id) {
    return <RegistrationForm />;
  }

  if (!data.id) {
    return <Error404 onClick={contactUs} errors={errorMassage} />;
  }

  return (
    <>
      <Formik
        innerRef={formikref}
        initialValues={{
          firstName: data?.customer_first_name || "",
          lastName: data?.customer_last_name || "",
          email: data?.customer_email || "",
          invoiceNumber: data?.invoice_number || "",
          planPurchaseDate: data?.contract_purchase_date
            ? dayjs(data?.contract_purchase_date, "YYYY-MM-DD")
            : null,
          deliveryDateOfProduct:
            data?.product_line_items_delivery_date_of_product
              ? dayjs(data?.product_line_items_delivery_date_of_product)
              : null,
          invoicePrice: data?.total_product_retail_value || "",
          purchasePrice: data?.contract_retail_price || "",
          street: data?.customer_street_address_1 || "",
          city: data?.customer_city || "",
          state: data?.customer_state || "",
          zipCode: data?.customer_postal_code || "",
          phone: data?.customer_primary_telephone || "",
          deliveryStreet: data?.customer_delivery_street_address_1 || "",
          deliveryCity: data?.customer_delivery_city || "",
          deliveryState: data?.customer_delivery_state || "",
          deliveryZipCode: data?.customer_delivery_postal_code || "",
          products: [
            {
              manufacturer: "",
              model_number: "",
              serial_number: "",
              product_retail_price: "",
              quantity: "",
            },
          ],
          fileAttachment: data?.customer_file_attachment || "",
          sameAddress: true,
          apiData: null,
          ...data,
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {(props) => (
          <FormikForm
            setSameAddress={setSameAddress}
            sameAddress={sameAddress}
            {...props}
          />
        )}
      </Formik>
    </>
  );
}

export default Step1;
