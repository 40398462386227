import React from "react";

function GbsLogo() {
  return (
    <svg
      fill="none"
      viewBox="0 0 176 19"
      height={14}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="#0b4eb2">
        <path d="m92.3717 0v11.109c0 .4509.0737.772.2211.9633.1475.1913.3955.2869.744.2869.201 0 .3685-.0136.5026-.041.134-.0273.2949-.0683.4825-.1229l-.2211 2.0906c-.2413.1503-.5563.2733-.945.3689-.3887.0957-.7707.1435-1.146.1435-1.0723 0-1.8497-.2528-2.3322-.7584-.4826-.5056-.7238-1.3322-.7238-2.48v-11.5599z" />
        <path
          clipRule="evenodd"
          d="m74.2414 14.5933 4.6645-13.5275h4.1417l4.6444 13.5275h-3.5587l-.9047-3.0539h-4.5841l-.9248 3.0539zm6.1322-8.71088-1.0053 3.2589h3.1365l-.9651-3.23841-.583-2.1316h-.0402z"
          fillRule="evenodd"
        />
        <path d="m98.9492 11.109v-11.109h-3.418v11.5599c0 1.1478.2413 1.9744.7238 2.48.4826.5056 1.26.7584 2.3323.7584.3753 0 .7573-.0478 1.146-.1435.3887-.0956.7037-.2186.9447-.3689l.221-2.0906c-.187.0546-.348.0956-.482.1229-.134.0274-.302.041-.5028.041-.3485 0-.5964-.0956-.7439-.2869-.1474-.1913-.2211-.5124-.2211-.9633z" />
        <path
          clipRule="evenodd"
          d="m6.09196 1.06584c1.60843 0 2.86167.39626 3.75972 1.18878.91142.77886 1.36712 1.88566 1.36712 3.32039 0 1.43474-.4557 2.54837-1.36712 3.34089-.89805.77885-2.15129 1.1683-3.75972 1.1683h-2.63382v4.5092h-3.45814v-13.52756zm-.54285 6.37433c1.46099 0 2.19149-.62172 2.19149-1.86516 0-1.24343-.7305-1.86515-2.19149-1.86515h-2.09097v3.73031z"
          fillRule="evenodd"
        />
        <path d="m13.1886 14.5934v-10.24816h3.0158l.1407 2.21359c.2413-.75153.6099-1.33908 1.1058-1.76267.5094-.43726 1.1594-.65588 1.9502-.65588.2547 0 .4692.02732.6434.08198.1877.04099.3351.08882.4423.14348l-.3418 2.86947c-.134-.04099-.3082-.08199-.5227-.12298-.2011-.04099-.449-.06149-.7439-.06149-.6166 0-1.1527.19813-1.6084.59439-.4424.39626-.6635.99065-.6635 1.78317v5.1651z" />
        <path
          clipRule="evenodd"
          d="m29.2695 4.77566c-.7908-.42359-1.7157-.63538-2.7746-.63538-1.0455 0-1.9703.21179-2.7745.63538-.7909.42359-1.4141 1.03164-1.8698 1.82416-.4424.79252-.6635 1.74901-.6635 2.86948 0 1.1205.2211 2.0769.6635 2.8695.4557.7925 1.0789 1.4005 1.8698 1.8241.8042.4236 1.729.6354 2.7745.6354 1.0589 0 1.9838-.2118 2.7746-.6354.8042-.4236 1.4275-1.0316 1.8698-1.8241.4557-.7926.6836-1.749.6836-2.8695 0-1.12047-.2279-2.07696-.6836-2.86948-.4423-.79252-1.0656-1.40057-1.8698-1.82416zm-4.1619 2.52104c.3351-.45092.7976-.67638 1.3873-.67638.6032 0 1.0656.22546 1.3873.67638.3351.43725.5026 1.16145.5026 2.1726 0 .9975-.1675 1.7217-.5026 2.1726-.3217.4509-.7841.6764-1.3873.6764-.5897 0-1.0522-.2255-1.3873-.6764-.3216-.4509-.4825-1.1751-.4825-2.1726 0-1.01115.1609-1.73535.4825-2.1726z"
          fillRule="evenodd"
        />
        <path d="m40.8648 4.34524v2.48004h-2.9957v4.11972c0 .4919.1139.8472.3418 1.0658.2413.205.5697.3075.9852.3075.3217 0 .5964-.0342.8243-.1025.2279-.082.4423-.1981.6434-.3484l.3619 2.1521c-.3351.2322-.764.4235-1.2868.5739-.5093.1366-1.0254.2049-1.5481.2049-1.1527 0-2.0642-.2459-2.7343-.7379-.6568-.5055-.9919-1.3527-1.0053-2.5415v-4.69362h-1.7291v-2.48004h1.7291v-2.29558l3.4179-.96332v3.2589z" />
        <path
          clipRule="evenodd"
          d="m43.3684 13.4046c.9785.9291 2.3055 1.3937 3.9809 1.3937.697 0 1.3605-.1025 1.9904-.3074.6434-.205 1.193-.5056 1.6487-.9019.4691-.4099.7975-.9086.9852-1.4962l-2.7344-.9223c-.1474.3689-.3753.6559-.6836.8608-.2948.205-.6969.3075-1.2063.3075-.5897 0-1.0656-.1503-1.4275-.4509-.3619-.3143-.5897-.8267-.6836-1.5373h6.856c.0134-.1639.0268-.36207.0402-.59436.0268-.24595.0402-.49874.0402-.75836 0-.94282-.1943-1.77634-.583-2.50054-.3887-.73786-.9584-1.31176-1.709-1.72168-.7372-.42359-1.6352-.63538-2.6941-.63538-1.0455 0-1.9637.21179-2.7545.63538-.7774.40992-1.394 1.01798-1.8497 1.82416-.4423.79252-.6635 1.74901-.6635 2.86948 0 1.6944.4826 3.0061 1.4476 3.9353zm1.8899-4.91912c.2011-1.27077.8445-1.90615 1.9302-1.90615 1.0186 0 1.6017.63538 1.7491 1.90615z"
          fillRule="evenodd"
        />
        <path d="m58.8517 4.14028c1.0186 0 1.8631.1503 2.5333.45091.6836.30062 1.213.69004 1.5883 1.16829.3753.47824.6166.99748.7238 1.55771l-3.1566 1.06581c-.0804-.58756-.2479-1.02482-.5026-1.31176-.2547-.30061-.6166-.45092-1.0857-.45092-.6434 0-1.1326.23229-1.4677.69687-.3217.46458-.4825 1.19561-.4825 2.1931 0 1.01111.1742 1.73531.5227 2.17261.3619.4236.8444.6354 1.4476.6354.9517 0 1.5079-.5534 1.6688-1.6602l3.0761 1.0043c-.1474.8882-.6367 1.6329-1.4677 2.2341-.8176.6012-1.9033.9018-3.2571.9018-1.6486 0-2.9689-.4646-3.9608-1.3937-.9784-.9429-1.4677-2.2546-1.4677-3.9353 0-1.12047.2212-2.07696.6635-2.86948.4557-.79252 1.079-1.40057 1.8698-1.82416s1.709-.63538 2.7545-.63538z" />
        <path d="m72.6919 6.82528v-2.48004h-2.9957v-3.2589l-3.4179.96332v2.29558h-1.7291v2.48004h1.7291v4.69362c.0134 1.1888.3485 2.036 1.0053 2.5415.6701.492 1.5816.7379 2.7343.7379.5227 0 1.0388-.0683 1.5481-.2049.5228-.1504.9517-.3417 1.2868-.5739l-.3619-2.1521c-.2011.1503-.4155.2664-.6434.3484-.2279.0683-.5026.1025-.8243.1025-.4155 0-.7439-.1025-.9852-.3075-.2278-.2186-.3418-.5739-.3418-1.0658v-4.11972z" />
      </g>
      <path
        d="m114.356 14.7983c-.818 0-1.515-.2255-2.091-.6764-.577-.4509-.992-1.0453-1.247-1.7832l-.12 2.2546h-2.996v-14.5933h3.418v6.10787c.268-.58755.663-1.05897 1.186-1.41424.536-.36893 1.193-.55339 1.97-.55339.778 0 1.468.21179 2.071.63538s1.079 1.03847 1.428 1.84466c.348.79252.522 1.75584.522 2.88997 0 1.66705-.382 2.96515-1.146 3.89425-.764.9292-1.762 1.3938-2.995 1.3938zm-1.187-2.5621c.59 0 1.053-.2186 1.388-.6558.335-.451.502-1.1547.502-2.11115 0-.95649-.167-1.65336-.502-2.09061-.335-.45092-.798-.67638-1.388-.67638-.576 0-1.032.24596-1.367.73787-.321.49191-.482 1.17511-.482 2.04962 0 .88815.167 1.57135.502 2.04965.336.4646.785.6968 1.347.6968z"
        fill="#4f98d7"
      />
      <path
        d="m130.051 4.3452-3.659 10.2481c-.376 1.0795-.785 1.9403-1.227 2.5825-.442.6559-.965 1.1205-1.568 1.3938-.603.2869-1.327.4304-2.172.4304-.576 0-1.099-.0615-1.568-.1845-.455-.1229-.858-.2937-1.206-.5124l.683-2.357c.242.1776.496.3006.764.3689.269.082.61.123 1.026.123.389 0 .704-.0684.945-.205.254-.123.456-.3826.603-.7788l.261-.6354-1.166-2.7875-3.016-7.6861h3.76l1.548 5.8414.483 2.1726.563-2.2546 1.548-5.7594z"
        fill="#4f98d7"
      />
      <path
        d="m142.298 14.7983c-1.18 0-2.232-.2665-3.157-.7994-.911-.5329-1.621-1.3186-2.131-2.357-.509-1.0385-.764-2.30246-.764-3.79185 0-1.4484.268-2.69183.804-3.73031.537-1.05214 1.301-1.85832 2.292-2.41855 1.006-.56023 2.185-.840349 3.539-.840349 1.515 0 2.728.280119 3.639.840349.925.54656 1.642 1.42107 2.151 2.62351l-3.196 1.29126c-.175-.69687-.483-1.20244-.925-1.51672-.443-.31427-.992-.47141-1.649-.47141-.992 0-1.763.3621-2.312 1.0863-.536.71054-.804 1.74901-.804 3.11543 0 1.4484.275 2.52784.824 3.23844.55.6968 1.374 1.0453 2.473 1.0453.764 0 1.401-.1845 1.91-.5534.509-.369.764-.936.764-1.70122v-.20496h-3.036v-2.35707h5.971v7.29665h-2.352l-.141-1.6602c-.375.6012-.891 1.0658-1.548 1.3938-.643.3142-1.427.4714-2.352.4714z"
        fill="#4f98d7"
      />
      <path
        d="m151.338 14.5933v-13.5275h6.454c1.636 0 2.842.31428 3.619.94283.778.62855 1.166 1.53038 1.166 2.7055 0 .76519-.221 1.4279-.663 1.98813s-1.119.90867-2.031 1.04531v.0205c1.019.13664 1.776.49874 2.272 1.0863.51.57389.764 1.27073.764 2.09063 0 1.1614-.409 2.0633-1.226 2.7055-.804.6285-2.004.9428-3.599.9428zm3.338-7.99352h2.513c1.233 0 1.85-.49191 1.85-1.47572 0-.98382-.617-1.47573-1.85-1.47573h-2.513zm0 5.51352h2.835c1.246 0 1.87-.5056 1.87-1.5168 0-.98377-.624-1.47568-1.87-1.47568h-2.835z"
        fill="#4f98d7"
      />
      <path
        d="m170.27.860841c1.233 0 2.318.198129 3.257.594389.951.3826 1.776.94966 2.473 1.70119l-1.669 2.27508c-.576-.61489-1.2-1.07264-1.87-1.37325-.657-.31427-1.407-.47141-2.252-.47141-.737 0-1.266.12298-1.588.36893s-.483.54657-.483.90183c0 .28695.128.53291.382.73787.269.1913.697.35527 1.287.49191l2.232.5124c1.327.31428 2.299.77203 2.915 1.37325.617.60122.925 1.40058.925 2.39807 0 .9565-.241 1.7695-.724 2.439-.482.6559-1.146 1.1547-1.99 1.4963-.845.3279-1.81.4919-2.895.4919-1.341 0-2.567-.2187-3.68-.6559-1.112-.4509-1.997-1.0385-2.654-1.7627l1.669-2.357c.496.5875 1.166 1.0794 2.011 1.4757.844.3826 1.715.5739 2.613.5739 1.394 0 2.091-.4168 2.091-1.2503 0-.2869-.107-.5261-.321-.7174-.201-.20492-.577-.37573-1.126-.51237l-2.453-.5534c-.751-.16397-1.421-.39626-2.011-.69687-.576-.30061-1.032-.69687-1.367-1.18878-.335-.50557-.502-1.13412-.502-1.88565 0-.84718.227-1.59871.683-2.25459.456-.66954 1.113-1.19561 1.971-1.57821.857-.38259 1.883-.573889 3.076-.573889z"
        fill="#4f98d7"
      />
    </svg>
  );
}

export default GbsLogo;
