import React from "react";
import { Logo } from "../../assets/images";
function Header() {
  const [isScrolled, setIsScrolled] = React.useState(false);
  React.useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const scrollThreshold = 250; // Adjust this value as needed

      setIsScrolled(scrollTop > scrollThreshold);
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`bg-white  py-4 header items-center flex ${
        isScrolled ? "scrolled" : ""
      }`}
    >
      <div className="container mx-auto px-6">
        <a href="/">
          <img src={Logo} alt="ProtectAll" />
        </a>
      </div>
    </div>
  );
}

export default Header;
