import Button from "../../components/Button";
import { useSearchParams } from "react-router-dom";

export default function Error404({ onClick, errors }: any) {
  const [searchParams] = useSearchParams();
  const registration_code = searchParams.get("rc");

  return (
    <div className="flex flex-grow items-center justify-center h-[calc(100vh-265px)]">
      <div className="rounded-lg text-center bg-white shadow block p-10">
        <h1 className="mb-4 text-9xl font-bold text-primary-100">404</h1>
        <p className="text-black-900">
          {!registration_code ? (
            <div className="text-lg text-gray-dark">Your details not found</div>
          ) : errors?.response?.data?.code ===
            "ERROR_CODE_TOO_MANY_REQUESTS" ? (
            <>
              <div className="text-2xl text-gray-800 font-medium">
                This code has already been used
              </div>
              <div className="text-lg  text-gray-400">
                Kindly try another one
              </div>
            </>
          ) : (
            <>
              <div className="text-2xl text-gray-800 font-medium">
                Unable to find verification code
              </div>
              <div className="text-lg  text-gray-400">
                Try to enter another code
              </div>
            </>
          )}
        </p>
        <Button
          type="submit"
          className="mt-8 inline-block rounded bg-primary-100 px-4 py-2 font-medium text-white hover:bg-primary-hover"
          label={"Contact Us"}
          onClick={onClick}
        />
      </div>
    </div>
  );
}
