import React, { ChangeEvent, InputHTMLAttributes } from "react";

interface InputTextProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  value?: string | number;
  type: string;
  maxLength?: number;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const InputText: React.FC<InputTextProps> = ({
  name,
  value,
  type,
  placeholder,
  onChange,
  disabled,
  maxLength,
  ...props
}) => {
  return (
    <div className="relative">
      <input
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
        {...props}
        maxLength={maxLength}
        className={`w-full appearance-none font-normal h-12 disabled:bg-bg-gray disabled:text-text-tertiaryy disabled:border-border-100 rounded-lg border border-border-100 p-3 placeholder:text-placeholder-100 min-width-320
                    focus:z-10 invalid:border-state-error hover:border-primary-100  focus:border-primary-100 focus-visible:outline-none ${props.className}`}
      />
    </div>
  );
};

export default InputText;
