import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

export default function Loading() {
  return (
    <div
      className="flex flex-grow items-center justify-center"
      style={{
        height: "calc(100vh - 265px)",
      }}
    >
      <CircularProgress />
    </div>
  );
}
