import React, { InputHTMLAttributes } from "react";
import InputText from "../InputText";
import { ErrorMessage, FormikHelpers, FormikValues } from "formik";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Values } from "../../types";

interface ProductProps extends InputHTMLAttributes<HTMLInputElement> {
  index: number;
  values: Values;
  setFieldValue: FormikHelpers<FormikValues>["setFieldValue"];
}

const AddProduct: React.FC<ProductProps> = ({
  index,
  onChange,
  onBlur,
  values,
  setFieldValue,
}) => {
  return (
    <div className="item border-l-2 border-primary-100 ps-3" key={index}>
      <div className="flex items-center justify-between">
        <h3 className="text-lg mt-0 mb-3 relative text-primary-100 font-semibold">
          Product {index + 1}
        </h3>
        {index > 0 && (
          <button
            type="button"
            className="bg-red-50 w-10 rounded-full text-rose-500  hover:bg-rose-500 hover:text-white h-10 text-sm flex justify-center items-center"
            onClick={() => {
              const updatedProducts = [...values?.products];
              updatedProducts.splice(index, 1);
              setFieldValue("products", updatedProducts);
            }}
          >
            <DeleteIcon className="text-sm" />
          </button>
        )}
      </div>
      <div className="grid grid-cols-12 gap-x-6 gap-y-4 mb-4">
        <div className="xl:col-span-6 lg:col-span-6 md:col-span-6 col-span-12">
          <div className="mb-3 relative">
            <span className="mb-1 block  text-base text-text-100">
              Manufacturer
              <i className="text-blue-500">*</i>
            </span>
            <InputText
              name={`products[${index}].manufacturer`}
              type="text"
              placeholder="Manufacturer"
              value={values?.products[index]?.manufacturer}
              onChange={onChange}
              onBlur={onBlur}
            />
            <ErrorMessage
              name={`products[${index}].manufacturer`}
              component="div"
              className="error text-red-500 text-sm absolute top-full left-0"
            />
          </div>
        </div>
        <div className="xl:col-span-6 lg:col-span-6 md:col-span-6 col-span-12">
          <div className="mb-3 relative ">
            <span className="mb-1 block  text-base text-text-100">
              Model Number
              <i className="text-blue-500">*</i>
            </span>
            <InputText
              name={`products[${index}].model_number`}
              type="text"
              placeholder="Model Number"
              value={values?.products[index]?.model_number}
              onChange={onChange}
              onBlur={onBlur}
            />
            <ErrorMessage
              name={`products[${index}].model_number`}
              component="div"
              className="error text-red-500 text-sm absolute top-full left-0"
            />
          </div>
        </div>
        <div className="xl:col-span-4 lg:col-span-4 md:col-span-6 col-span-12">
          <div className="mb-3 relative">
            <span className="mb-1 block  text-base text-text-100">
              Serial Number
              <i className="text-blue-500">*</i>
            </span>
            <InputText
              name={`products[${index}].serial_number`}
              type="text"
              placeholder="Serial Number"
              value={values?.products[index]?.serial_number}
              onChange={onChange}
              onBlur={onBlur}
            />
            <ErrorMessage
              name={`products[${index}].serial_number`}
              component="div"
              className="error text-red-500 text-sm absolute top-full left-0"
            />
          </div>
        </div>

        <div className="xl:col-span-4 lg:col-span-4 md:col-span-6 col-span-12">
          <div className="mb-3 relative">
            <span className="mb-1 block  text-base text-text-100">
              Product Price
              <i className="text-blue-500">*</i>
            </span>
            <InputText
              name={`products[${index}].product_retail_price`}
              type="text"
              placeholder="Product Price"
              value={values?.products[index]?.product_retail_price}
              onChange={onChange}
              onBlur={onBlur}
            />
            <ErrorMessage
              name={`products[${index}].product_retail_price`}
              component="div"
              className="error text-red-500 text-sm absolute top-full left-0"
            />
          </div>
        </div>
        <div className="xl:col-span-4 lg:col-span-4 md:col-span-6 col-span-12">
          <div className="mb-3 relative">
            <span className="mb-1 block  text-base text-text-100">
              Quantity
              <i className="text-blue-500">*</i>
            </span>
            <InputText
              name={`products[${index}].quantity`}
              type="text"
              placeholder="Quantity"
              value={values?.products[index]?.quantity}
              onChange={onChange}
              onBlur={onBlur}
            />
            <ErrorMessage
              name={`products[${index}].quantity`}
              component="div"
              className="error text-red-500 text-sm absolute top-full left-0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddProduct;
