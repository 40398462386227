import { useEffect } from "react";
import { Square } from "../../assets/images";
import InputText from "../InputText";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Form, ErrorMessage } from "formik";
import "react-toastify/dist/ReactToastify.css";
import AddProduct from "../AddProduct";
import Button from "../Button";
import { PatternFormat } from "react-number-format";

const FormikForm = (props: any) => {
  const {
    sameAddress,
    setSameAddress,
    handleBlur,
    setFieldValue,
    handleChange,
    values,
    submitCount,
    errors,
    isValid,
  } = props;

  useEffect(() => {
    if (isValid) return;

    const fieldErrorNames = getFieldErrorNames(errors);
    if (fieldErrorNames.length <= 0) return;

    let element = document.querySelector(`input[name="${fieldErrorNames[0]}"]`);
    if (!element) return;

    element.scrollIntoView({ behavior: "smooth", block: "center" });
  }, [submitCount]);

  const getFieldErrorNames = (formikErrors: any) => {
    const transformObjectToDotNotation = (
      obj: any,
      prefix = "",
      result = []
    ) => {
      Object.keys(obj).forEach((key) => {
        const value = obj[key];
        if (!value) return;

        const nextKey = prefix ? `${prefix}.${key}` : key;
        if (typeof value === "object") {
          transformObjectToDotNotation(value, nextKey, result);
        } else {
          result.push(nextKey as never);
        }
      });

      return result;
    };

    return transformObjectToDotNotation(formikErrors);
  };

  return (
    <Form key={submitCount}>
      <div className="my-9 text-center">
        <h2 className="fs27 sm:text-xl md:font-semibold font-semibold">
          Register Your PROTECTALL™ Protection Plan
        </h2>
      </div>
      <div className="bg-white regi-form mb-30">
        <div className="p-6 regi-form-body">
          <div className="title flex items-center gap-2 mb-3">
            <img src={Square} alt="Square" />
            <h3 className="fs20 m-0">Purchase Date </h3>
          </div>
          <div className="grid grid-cols-12 gap-x-6 gap-y-4 mb-4">
            <div className="xl:col-span-4 lg:col-span-4 md:col-span-6 col-span-12">
              <div className="mb-3 relative">
                <span className=" mb-1 block  text-text-100">
                  Invoice Number<i className="text-blue-500">*</i>
                </span>
                <InputText
                  name="invoiceNumber"
                  type="text"
                  placeholder="Invoice Number"
                  value={values.invoiceNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  name="invoiceNumber"
                  component="div"
                  className="error text-red-500 text-sm absolute"
                />
              </div>
            </div>
            <div className="xl:col-span-4 lg:col-span-4 md:col-span-6 col-span-12">
              <div className="mb-3 relative">
                <span className=" mb-1 block  text-base text-text-100">
                  Plan Purchase Date
                  <i className="text-blue-500">*</i>
                </span>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{
                      width: "100%",
                      "& .MuiInputBase-root": {
                        borderRadius: "8px",
                        height: "48px",
                        borderColor: "#cbd5e1",
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#2D6CCA",
                        },
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #CBD5E1",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#2D6CCA",
                      },
                      emptyLabel: "Plan Purchase Date",
                    }}
                    onChange={(date) => {
                      setFieldValue("planPurchaseDate", date);
                    }}
                    slotProps={{
                      textField: {
                        style: {
                          fontWeight: 300,
                          fontFamily: "Work Sans",
                          color: "#979FAC",
                        },
                        placeholder: "Plan Purchase Date",
                        name: "planPurchaseDate",
                      },
                    }}
                    disableFuture={true}
                    value={values?.planPurchaseDate}
                  />
                </LocalizationProvider>
                <ErrorMessage
                  name="planPurchaseDate"
                  component="div"
                  className="error text-red-500 text-sm absolute"
                />
              </div>
            </div>
            <div className="xl:col-span-4 lg:col-span-4 md:col-span-6 col-span-12">
              <div className="mb-3 relative">
                <span className=" mb-1 block  text-base text-text-100">
                  Delivery Date Of Product
                  <i className="text-blue-500">*</i>
                </span>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{
                      width: "100%",
                      "& .MuiInputBase-root": {
                        borderRadius: "8px",
                        height: "48px",
                        borderColor: "#cbd5e1",
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#2D6CCA",
                        },
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #CBD5E1",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#2D6CCA",
                      },
                    }}
                    onChange={(date) =>
                      setFieldValue("deliveryDateOfProduct", date)
                    }
                    slotProps={{
                      textField: {
                        style: {
                          fontWeight: 300,
                          fontFamily: "Work Sans",
                          color: "#979FAC",
                        },
                        placeholder: "Delivery Date Of Product",
                        name: "deliveryDateOfProduct",
                      },
                    }}
                    disableFuture={true}
                    value={values?.deliveryDateOfProduct}
                  />
                </LocalizationProvider>
                <ErrorMessage
                  name="deliveryDateOfProduct"
                  component="div"
                  className="error text-red-500 text-sm absolute"
                />
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-6 md:col-span-6 col-span-12">
              <div className="mb-3 relative">
                <span className=" mb-1 block  text-base text-text-100">
                  Protection Plan Purchase Price
                  <i className="text-blue-500">*</i>
                </span>
                <InputText
                  name="purchasePrice"
                  type="text"
                  placeholder="Plan Purchase Price"
                  value={values.purchasePrice}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  name="purchasePrice"
                  component="div"
                  className="error text-red-500 text-sm absolute"
                />
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-6 md:col-span-6 col-span-12">
              <div className="mb-3 relative">
                <span className=" mb-1 block  text-base text-text-100">
                  Total Retail Price Paid On Invoice
                  <i className="text-blue-500">*</i>
                </span>
                <InputText
                  name="invoicePrice"
                  type="text"
                  placeholder="Total Retail Price Paid On Invoice"
                  value={values.invoicePrice}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  name="invoicePrice"
                  component="div"
                  className="error text-red-500 text-sm absolute"
                />
              </div>
            </div>
          </div>
          <div className="title flex items-center gap-2 mb-3">
            <img src={Square} alt="Square" />
            <h3 className="fs20 m-0">Personal information</h3>
          </div>
          <div className="grid grid-cols-12 gap-x-6 gap-y-4 mb-4">
            <div className="xl:col-span-6 lg:col-span-6 md:col-span-6 col-span-12">
              <div className="mb-3 relative">
                <span className=" mb-1 block  text-text-100">
                  First Name<i className="text-blue-500">*</i>
                </span>
                <InputText
                  name="firstName"
                  type="text"
                  placeholder="First name"
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  name="firstName"
                  component="div"
                  className="error text-red-500 text-sm absolute"
                />
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-6 md:col-span-6 col-span-12">
              <span className=" mb-1 block  text-base text-text-100">
                Last Name<i className="text-blue-500">*</i>
              </span>
              <InputText
                name="lastName"
                type="text"
                placeholder="Last name"
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <ErrorMessage
                name="lastName"
                component="div"
                className="error text-red-500 text-sm absolute"
              />
            </div>
            <div className="xl:col-span-3 lg:col-span-3 md:col-span-6 col-span-12">
              <div className="mb-3 relative">
                <span className="mb-1 block  text-base text-text-100">
                  Street<i className="text-blue-500">*</i>
                </span>
                <InputText
                  name="street"
                  type="text"
                  placeholder="Street"
                  value={values.street}
                  onChange={(e) => {
                    setSameAddress(false);
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  name="street"
                  component="div"
                  className="error text-red-500 text-sm absolute"
                />
              </div>
            </div>
            <div className="xl:col-span-3 lg:col-span-3 md:col-span-6 col-span-12">
              <div className="mb-3 relative">
                <span className="mb-1 block  text-base text-text-100">
                  City<i className="text-blue-500">*</i>
                </span>
                <InputText
                  name="city"
                  type="text"
                  placeholder="City"
                  value={values.city}
                  onChange={(e) => {
                    setSameAddress(false);
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  name="city"
                  component="div"
                  className="error text-red-500 text-sm absolute"
                />
              </div>
            </div>
            <div className="xl:col-span-3 lg:col-span-3 md:col-span-6 col-span-12">
              <div className="mb-3 relative">
                <span className="mb-1 block  text-base text-text-100">
                  State<i className="text-blue-500">*</i>
                </span>
                <InputText
                  name="state"
                  type="text"
                  placeholder="State"
                  value={values.state}
                  onChange={(e) => {
                    setSameAddress(false);
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  name="state"
                  component="div"
                  className="error text-red-500 text-sm absolute"
                />
              </div>
            </div>
            <div className="xl:col-span-3 lg:col-span-3 md:col-span-6 col-span-12">
              <div className="mb-3 relative">
                <span className="mb-1 block  text-base text-text-100">
                  Zip Code<i className="text-blue-500">*</i>
                </span>
                <InputText
                  name="zipCode"
                  type="text"
                  placeholder="Zip Code"
                  value={values.zipCode}
                  onChange={(e) => {
                    setSameAddress(false);
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  name="zipCode"
                  component="div"
                  className="error text-red-500 text-sm absolute"
                />
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-6 md:col-span-6 col-span-12">
              <div className="mb-3 relative">
                <span className="mb-1 block  text-base text-text-100">
                  Email<i className="text-blue-500">*</i>
                </span>
                <InputText
                  name="email"
                  type="text"
                  placeholder="Email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="error text-red-500 text-sm absolute"
                />
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-6 md:col-span-6 col-span-12">
              <div className="mb-3 relative">
                <span className="mb-1 block  text-base text-text-100">
                  Phone<i className="text-blue-500">*</i>
                </span>
                <PatternFormat
                  className="w-full appearance-none font-normal h-12 disabled:bg-bg-gray disabled:text-text-tertiaryy disabled:border-border-100 rounded-lg border border-border-100 p-3 placeholder:text-placeholder-100 focus:z-10 invalid:border-state-error hover:border-primary-100  focus:border-primary-100 focus-visible:outline-none undefined"
                  format="(###) ### ####"
                  mask="_"
                  name="phone"
                  type="text"
                  placeholder="Phone"
                  value={values?.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  name="phone"
                  component="div"
                  className="error text-red-500 text-sm absolute"
                />
              </div>
            </div>
          </div>
          <div className="title flex items-center gap-2 mb-3">
            <img src={Square} alt="Square" />
            <h3 className="fs20 m-0">File upload</h3>
          </div>
          <div className="grid grid-cols-12 gap-x-6 gap-y-4 mb-4">
            <div className="col-span-12">
              <div className="-mb-3 block  text-text-100">
                Please upload one of the following: Receipt, Invoice, Order
                Confirmation
                <i className="text-blue-500">*</i>
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-6 md:col-span-6 col-span-12">
              <div className="mb-3 relative">
                <div className="flex justify-between h-12 relative rounded-lg border  border-border-100  placeholder:text-placeholder-100 ">
                  <input
                    name="fileAttachment"
                    type="file"
                    placeholder="Upload file"
                    className="absolute top-0 left-0 w-full h-full opacity-0 "
                    onChange={(e) => {
                      setFieldValue("fileAttachment", e?.target?.files?.[0]);
                    }}
                    onBlur={handleBlur}
                    accept=".jpg,.png,.pdf,.jpeg"
                  />
                  <p className="h-12 flex items-center ps-3 text-placeholder-100 ">
                    {values.fileAttachment?.name || "Upload a file"}
                  </p>
                  <Button
                    type="button"
                    aria-label="fileAttachment"
                    className="bg-gray-dark text-white w-28 rounded-lg  rounded-tl-none rounded-bl-none"
                    label={"Upload"}
                  />
                </div>
                <ErrorMessage
                  name="fileAttachment"
                  component="div"
                  className="error text-red-500 text-sm absolute"
                />
              </div>
            </div>
          </div>
          <div className="title flex items-center gap-2 mb-3 flex-wrap">
            <img src={Square} alt="Square" />
            <h3 className="fs20 m-0">Delivery address</h3>
            <div className="checkbox md:ms-4 ms-0">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sameAddress}
                      onChange={(e) => {
                        setSameAddress((sameAddress: any) => !sameAddress);
                        if (e.target.checked) {
                          setFieldValue("deliveryStreet", values.street);
                          setFieldValue("deliveryCity", values.city);
                          setFieldValue("deliveryState", values.state);
                          setFieldValue("deliveryZipCode", values.zipCode);
                        }
                      }}
                    />
                  }
                  label={
                    <span className="text-gray-600">
                      Delivery address same as personal address?
                    </span>
                  }
                />
              </FormGroup>
            </div>
          </div>
          <div className="grid grid-cols-12 gap-x-6 gap-y-4 mb-4">
            <div className="xl:col-span-3 lg:col-span-3 md:col-span-6 col-span-12">
              <div className="mb-3 relative">
                <span className="mb-1 block  text-base text-text-100">
                  Delivery Street
                </span>
                <InputText
                  name="deliveryStreet"
                  type="text"
                  placeholder="Delivery Street"
                  value={values.deliveryStreet}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="xl:col-span-3 lg:col-span-3 md:col-span-6 col-span-12">
              <div className="mb-3 relative">
                <span className="mb-1 block  text-base text-text-100">
                  Delivery City
                </span>
                <InputText
                  name="deliveryCity"
                  type="text"
                  placeholder="Delivery City"
                  value={values.deliveryCity}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="xl:col-span-3 lg:col-span-3 md:col-span-6 col-span-12">
              <div className="mb-3 relative">
                <span className="mb-1 block  text-base text-text-100">
                  Delivery State
                </span>
                <InputText
                  name="deliveryState"
                  type="text"
                  placeholder="Delivery State"
                  value={values.deliveryState}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="xl:col-span-3 lg:col-span-3 md:col-span-6 col-span-12">
              <div className="mb-3 relative">
                <span className="mb-1 block  text-base text-text-100">
                  Delivery Zip Code
                </span>
                <InputText
                  name="deliveryZipCode"
                  type="text"
                  placeholder="Delivery Zip Code"
                  value={values.deliveryZipCode}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          <div className="title flex items-center gap-2 mb-3">
            <img src={Square} alt="Square" />
            <h3 className="fs20 m-0">Product information</h3>
          </div>
          {values.products.map((product: any, index: any) => (
            <AddProduct
              index={index}
              onChange={handleChange}
              onBlur={handleBlur}
              values={values}
              setFieldValue={setFieldValue}
              key={index}
            />
          ))}
          <div className="border-t border-border-100 flex justify-end py-4">
            <button
              type="button"
              aria-label="+ Add product"
              className="flex justify-center items-center px-4 rounded-md h-10 text-primary-100 border border-primary-100 hover:bg-primary-100 hover:text-white "
              onClick={() => {
                setFieldValue("products", [
                  ...values.products,
                  {
                    manufacturer: "",
                    model_number: "",
                    serial_number: "",
                    product_retail_price: "",
                    quantity: "",
                  },
                ]);
              }}
            >
              + Add product
            </button>
          </div>
        </div>
        <div className="regi-form-footer border-t border-border-100 py-5 flex justify-center">
          <Button
            label={"Next"}
            type="submit"
            className={
              "bg-primary-100 flex justify-center items-center px-3 py-4 rounded-full text-white hover:bg-primary-hover disabled:bg-blue-200 ml-2 mr-2"
            }
            style={{ width: "16rem" }}
          />
        </div>
      </div>
    </Form>
  );
};

export default FormikForm;
