import React, { useState } from "react";
import InputText from "../components/InputText";
import { Square } from "../assets/images";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import { AWSaxiosConsumer } from "../service";
import Button from "../components/Button";
import { toast } from "react-toastify";
import { PatternFormat } from "react-number-format";

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(/^[A-Za-z\s]+$/, "Invalid firstname format")
    .required("First name is required"),
  lastName: Yup.string()
    .matches(/^[A-Za-z\s]+$/, "Invalid lastname format")
    .required("Last name is required"),
  invoiceNumber: Yup.string().required("Invoice number is required"),
  dealerName: Yup.string()
    .matches(/^[A-Za-z\s]+$/, "Invalid delearname format")
    .required("Dealer name is required"),
  emailAddress: Yup.string()
    .email("Invalid email address")
    .matches(
      /^[a-zA-Z0-9.+]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      "Invalid email address"
    )
    .required("Email is required"),
  phone: Yup.string().required("Phone number is required"),
});

export default function ContactUs() {
  const [loader, setLoader] = useState(false);

  const handleSubmit = async (values: any, { resetForm }: any) => {
    const body = {
      first_name: values?.firstName,
      last_name: values?.lastName,
      dealer_name: values?.dealerName,
      invoice_number: values?.invoiceNumber,
      phone_number: values?.phone,
      email_address: values?.emailAddress,
    };
    try {
      setLoader(true);
      const { data } = await AWSaxiosConsumer.post(
        "/send_pre_sold_inquiry_email",
        body
      );
      if (data) {
        toast.success("Thank you for your inquiry. Submission successful!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      resetForm();
    } catch (error: any) {
      console.log("error", error);
    }
    setLoader(false);
  };

  return (
    <div className="container mx-auto min-h-[calc(100vh-298px)] regi-form-container px-6 mt-9">
      <div className="bg-white regi-form mb-30">
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            dealerName: "",
            invoiceNumber: "",
            phone: "",
            emailAddress: "",
          }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ handleChange, handleBlur, handleSubmit, values }) => (
            <Form>
              <div className="p-6 regi-form-body">
                <div className="title flex items-center gap-2 mb-3">
                  <img src={Square} alt="Square" />
                  <h3 className="fs20 m-0"> Contact Service </h3>
                </div>
                <div className="grid grid-cols-12 gap-x-6 gap-y-4 mb-4">
                  <div className="xl:col-span-4 lg:col-span-4 md:col-span-6 col-span-12 ">
                    <div className="mb-3 relative">
                      <span className=" mb-1 block  text-text-100">
                        First Name<i className="text-blue-500">*</i>
                      </span>
                      <InputText
                        name="firstName"
                        type="text"
                        placeholder="First Name"
                        value={values?.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage
                        name="firstName"
                        component="div"
                        className="error text-red-500 text-sm absolute left-0 top-full"
                      />
                    </div>
                  </div>
                  <div className="xl:col-span-4 lg:col-span-4 md:col-span-6 col-span-12">
                    <div className="mb-3 relative">
                      <span className=" mb-1 block  text-text-100">
                        Last Name<i className="text-blue-500">*</i>
                      </span>
                      <InputText
                        name="lastName"
                        type="text"
                        placeholder="Last Name"
                        value={values?.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className="error text-red-500 text-sm absolute left-0 top-full"
                      />
                    </div>
                  </div>
                  <div className="xl:col-span-4 lg:col-span-4 md:col-span-6 col-span-12">
                    <div className="mb-3 relative">
                      <span className=" mb-1 block  text-text-100">
                        Dealer Name<i className="text-blue-500">*</i>
                      </span>
                      <InputText
                        name="dealerName"
                        type="text"
                        placeholder="Dealer Name"
                        value={values?.dealerName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage
                        name="dealerName"
                        component="div"
                        className="error text-red-500 text-sm absolute left-0 top-full"
                      />
                    </div>
                  </div>
                  <div className="xl:col-span-4 lg:col-span-4 md:col-span-6 col-span-12">
                    <div className="mb-3 relative">
                      <span className=" mb-1 block  text-text-100">
                        Invoice Number<i className="text-blue-500">*</i>
                      </span>
                      <InputText
                        name="invoiceNumber"
                        type="text"
                        placeholder="Invoice Number"
                        value={values?.invoiceNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage
                        name="invoiceNumber"
                        component="div"
                        className="error text-red-500 text-sm absolute left-0 top-full"
                      />
                    </div>
                  </div>
                  <div className="xl:col-span-4 lg:col-span-4 md:col-span-6 col-span-12">
                    <div className="mb-3 relative">
                      <span className=" mb-1 block  text-text-100">
                        Phone Number<i className="text-blue-500">*</i>
                      </span>
                      <PatternFormat
                        className="w-full appearance-none font-normal h-12 disabled:bg-bg-gray disabled:text-text-tertiaryy disabled:border-border-100 rounded-lg border border-border-100 p-3 placeholder:text-placeholder-100 focus:z-10 invalid:border-state-error hover:border-primary-100  focus:border-primary-100 focus-visible:outline-none undefined"
                        format="(###) ### ####"
                        name="phone"
                        type="text"
                        placeholder="Phone"
                        value={values?.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage
                        name="phone"
                        component="div"
                        className="error text-red-500 text-sm absolute left-0 top-full"
                      />
                    </div>
                  </div>
                  <div className="xl:col-span-4 lg:col-span-4 md:col-span-6 col-span-12">
                    <div className="mb-3 relative">
                      <span className=" mb-1 block  text-base text-text-100">
                        Email Address
                        <i className="text-blue-500">*</i>
                      </span>
                      <InputText
                        name="emailAddress"
                        type="text"
                        placeholder="Email Address"
                        value={values?.emailAddress}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage
                        name="emailAddress"
                        component="div"
                        className="error text-red-500 text-sm absolute left-0 top-full"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="regi-form-footer border-t border-border-100 py-5 flex justify-center">
                <Button
                  type="submit"
                  onClick={handleSubmit}
                  loader={loader}
                  label={"Submit"}
                  className="bg-primary-100 flex justify-center items-center px-20 py-4 rounded-full text-white hover:bg-primary-hover disabled:bg-blue-200 ml-2 mr-2"
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
