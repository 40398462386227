import React from "react";
import GbsLogo from "../GbsLogo";

function Footer() {
  return (
    <div className="footer">
      <div className="container mx-auto">
        <div className="grid grid-cols-12 gap-4 mb-4">
          <div className="xl:col-span-5 lg:col-span-5 md:col-span-4 col-span-12  lg:ps-0 md:px-3">
            <div className="px-6">
              <GbsLogo />
              <div className="address">
                <p>Terms of Service | Privacy Policy</p>
                <p>© 2024 All Rights Reserved. ProtectAll USA, LLC</p>
              </div>
            </div>
          </div>
          <div className="xl:col-span-3 lg:col-span-3 md:col-span-4  col-span-12 flex lg:ps-0 md:px-3">
            <div className="md:border-l border-border-200 px-6">
              <p>9151 Boulevard 26,</p>
              <p>Suite 100B,</p>
              <p>North Richland Hills, Texas 76180</p>
            </div>
          </div>
          <div className="xl:col-span-4 lg:col-span-4 md:col-span-4 col-span-12 flex lg:ps-0 md:px-3">
            <div className="md:border-l border-border-200 px-6">
              <p className="flex items-center gap-2 mb-3 flex-wrap">
                <svg
                  name="email"
                  viewBox="0 0 24 19"
                  xmlns="http://www.w3.org/2000/svg"
                  className="fill-primary-100 h-4"
                >
                  <path d="M23 7.965a1 1 0 0 1 1 1v6.693c0 1.816-1.422 3.307-3.2 3.307H3.2c-1.778 0-3.2-1.491-3.2-3.307V8.965a1 1 0 1 1 2 0v6.693c0 .732.548 1.307 1.2 1.307h17.6c.652 0 1.2-.575 1.2-1.307V8.965a1 1 0 0 1 1-1zM20.8 0C22.562 0 24 1.401 24 3.143v2.143a1 1 0 0 1-.539.887l-11 5.714a1 1 0 0 1-.922 0l-11-5.714A1 1 0 0 1 0 5.286V3.143C0 1.4 1.438 0 3.2 0zm0 2H3.2C2.531 2 2 2.518 2 3.143v1.535l10 5.195 10-5.195V3.143C22 2.518 21.469 2 20.8 2z"></path>
                </svg>
                <a
                  href="mailto:support@myprotectall.com"
                  className="text-primary-100"
                >
                  support@myprotectall.com
                </a>
              </p>
              <p>
                For assistance or additional information, please call the Toll
                Free Number listed in your Terms and Conditions.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
