import { createSlice } from "@reduxjs/toolkit";

const RegistrationDataSlice = createSlice({
  name: "Registration_Data",
  initialState: {
    apiData: {},
  },
  reducers: {
    regiFormData: (state, action) => {
      return {
        ...state,
        apiData: {
          ...action.payload,
          name: action.payload.customer_file_attachment?.name,
          type: action.payload.customer_file_attachment?.type,
          src: action.payload.customer_file_attachment?.src,
        },
      };
    },
    cleanUpValues: () => ({
      apiData: {},
    }),
  },
});

export const { regiFormData, cleanUpValues } = RegistrationDataSlice.actions;

export default RegistrationDataSlice.reducer;
