import React, { useState } from "react";
import Step1 from "./step-1";
import Step2 from "./step-2";

const App = () => {
  const [step, setStep] = useState(1);

  const previusStep = () => {
    setStep((old) => old - 1);
  };

  const nextStep = () => {
    setStep((old) => ++old);
  };
  return (
    <>
      <div className="container mx-auto regi-form-container px-6">
        {step === 1 && <Step1 nextStep={nextStep} />}
        {step === 2 && <Step2 previusStep={previusStep} />}
      </div>
    </>
  );
};

export default App;
