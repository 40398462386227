import { Environment } from "./types";

const environments = {
  development: {
    API_URL: "https://www.api-myprotectall.com/api:z06gzYNL",
    XANO_DATA_SOURCE: "live",
    SENDGRID_SEND_EMAIL_URL:
      "https://dev-api.myprotectall.com/consumer_portal/v1",
    SENDGRID_SEND_EMAIL_API_KEY: "D1k9NHQ92L6TqRRmMGdim8MHagM8sDXV8sQA7j7y",
  },
  production: {
    API_URL: "https://www.api-myprotectall.com/api:aUakSIM-",
    XANO_DATA_SOURCE: "prod",
    SENDGRID_SEND_EMAIL_URL:
      "https://dev-api.myprotectall.com/consumer_portal/v1",
    SENDGRID_SEND_EMAIL_API_KEY: "D1k9NHQ92L6TqRRmMGdim8MHagM8sDXV8sQA7j7y",
  },
};

var config: Environment;
if (
  process.env.REACT_APP_ENV == "development" ||
  process.env.REACT_APP_ENV == "production"
) {
  config = environments[process.env.REACT_APP_ENV];
} else {
  config = environments.development;
}

export default config;
