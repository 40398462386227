import { useState } from "react";
import { PdfIcon, Square } from "../../assets/images";
import { axiosConsumer } from "../../service";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { useNavigate, useSearchParams } from "react-router-dom";
import Button from "../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  cleanUpValues,
  regiFormData,
} from "../../store/registration-dataSlice";

function Step2({ previusStep }: any) {
  const navigate = useNavigate();
  const [showDoneModal, setShowDoneModal] = useState(false);
  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const registration_code = searchParams.get("rc");
  const data = useSelector((state: any) => state.RegistrationData.apiData);

  const handleSubmit = async (code = registration_code) => {
    try {
      setLoader(true);
      await axiosConsumer.put(`/pre_sold_contract/registration_code/${code}`);
      // dispatch(regiFormData(data));
      setShowDoneModal(true);
      setLoader(false);
    } catch (error: any) {
      setLoader(false);
    }
  };

  const bakeToHome = () => {
    navigate("/");
  };

  const backToRegister = async () => {
    dispatch(regiFormData(data));
    await previusStep();
  };

  return (
    <>
      <div className="my-9 text-center">
        <h2 className="fs27 sm:text-xl md:font-medium font-medium">
          Register Your PROTECTALL™ Protection Plan
        </h2>
      </div>
      <div className="bg-white regi-form mb-20">
        <div className="container mx-auto regi-form-container px-6">
          <div className="pt-4 regi-form-body">
            <div className="title flex items-center gap-2 mb-3">
              <img src={Square} alt="Square" />
              <h3 className="fs20 m-0">Purchase Date </h3>
            </div>
            <div className="grid grid-cols-12 lg:gap-4 md:gap-2 gap-1">
              <div className="xl:col-span-3 lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12">
                <div className="mb-2">
                  <span className="  block   text-text-100   font-medium ">
                    Invoice Number
                  </span>
                  <div>
                    <span className="text-gray-500">
                      {data?.invoice_number}
                    </span>
                  </div>
                </div>
              </div>
              <div className="xl:col-span-3 lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12">
                <div className="mb-2">
                  <span className="  block  text-base  text-text-100  font-medium ">
                    Plan Purchase Date
                  </span>
                  <div>
                    <span className="text-gray-500">
                      {data?.contract_purchase_date}
                    </span>
                  </div>
                </div>
              </div>
              <div className="xl:col-span-3 lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12">
                <div className="mb-2">
                  <span className="  block  text-base  text-text-100  font-medium ">
                    Delivery Date Of Product
                  </span>
                  <div>
                    <span className="text-gray-500">
                      {data?.product_line_items_delivery_date_of_product}
                    </span>
                  </div>
                </div>
              </div>
              <div className="xl:col-span-3 lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12">
                <div className="mb-2">
                  <span className="  block  text-base  text-text-100  font-medium ">
                    Protection Plan Purchase Price
                  </span>
                  <div>
                    <span className="text-gray-500">
                      {data?.contract_retail_price}
                    </span>
                  </div>
                </div>
              </div>
              <div className="xl:col-span-4 lg:col-span-4 md:col-span-4 sm:col-span-6 col-span-12">
                <div className="mb-2">
                  <span className="  block  text-base  text-text-100  font-medium ">
                    Total Retail Price Paid On Invoice
                  </span>
                  <div>
                    <span className="text-gray-500">
                      {data?.total_product_retail_value}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <hr className="mb-4" />
            <div className="title flex items-center gap-2 mb-3">
              <img src={Square} alt="Square" />
              <h3 className="fs20 m-0">Personal information</h3>
            </div>
            <div className="grid grid-cols-12 lg:gap-4 md:gap-2 gap-1">
              <div className="xl:col-span-3 lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12">
                <div className="mb-2">
                  <span className="  block   text-text-100  font-medium ">
                    First Name
                  </span>
                  <div>
                    <span className="text-gray-500">
                      {data?.customer_first_name}
                    </span>
                  </div>
                </div>
              </div>
              <div className="xl:col-span-3 lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12">
                <span className="  block  text-base  text-text-100  font-medium ">
                  Last Name
                </span>
                <div>
                  <span className="text-gray-500">
                    {data?.customer_last_name}
                  </span>
                </div>
              </div>
              <div className="xl:col-span-3 lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12">
                <div className="mb-2">
                  <span className=" block  text-base  text-text-100  font-medium ">
                    Street
                  </span>
                  <div>
                    <span className="text-gray-500 text-pretty">
                      {data?.customer_street_address_1}
                    </span>
                  </div>
                </div>
              </div>
              <div className="xl:col-span-3 lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12">
                <div className="mb-2">
                  <span className=" block  text-base  text-text-100  font-medium ">
                    City
                  </span>
                  <div>
                    <span className="text-gray-500 text-pretty">
                      {data?.customer_city}
                    </span>
                  </div>
                </div>
              </div>
              <div className="xl:col-span-3 lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12">
                <div className="mb-2">
                  <span className=" block  text-base  text-text-100  font-medium ">
                    State
                  </span>
                  <div>
                    <span className="text-gray-500 text-pretty">
                      {data?.customer_state}
                    </span>
                  </div>
                </div>
              </div>
              <div className="xl:col-span-3 lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12">
                <div className="mb-2">
                  <span className=" block  text-base  text-text-100  font-medium ">
                    Zip Code
                  </span>
                  <div>
                    <span className="text-gray-500">
                      {data?.customer_postal_code}
                    </span>
                  </div>
                </div>
              </div>
              <div className="xl:col-span-3 lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12">
                <div className="mb-2">
                  <span className=" block  text-base  text-text-100 font-medium ">
                    Email
                  </span>
                  <div>
                    <span className="text-gray-500 text-pretty">
                      {data?.customer_email}
                    </span>
                  </div>
                </div>
              </div>
              <div className="xl:col-span-3 lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12">
                <div className="mb-2">
                  <span className=" block  text-base text-text-100  font-medium ">
                    Phone
                  </span>
                  <div>
                    <span className="text-gray-500">
                      {data?.customer_primary_telephone}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <hr className="mb-4" />
            <div className="title flex items-center gap-2 mb-3">
              <img src={Square} alt="Square" />
              <h3 className="fs20 m-0">File upload</h3>
            </div>
            <div className="mb-2">
              <span>
                {data.customer_file_attachment?.src ? (
                  <span>
                    {data.customer_file_attachment?.type.startsWith(
                      "image/"
                    ) ? (
                      <img
                        src={data.customer_file_attachment?.src}
                        className="h-32 w-32 max-w-full max-h-full object-cover"
                        alt="Uploaded"
                      />
                    ) : data.customer_file_attachment?.type.startsWith(
                        "application/pdf"
                      ) ? (
                      <img
                        src={PdfIcon}
                        className="h-24 max-w-full max-h-full object-contain"
                        alt="PDF Icon"
                      />
                    ) : (
                      "Upload Valid File"
                    )}
                  </span>
                ) : (
                  "Please Upload File"
                )}
              </span>
            </div>
            <hr className="mb-4" />
            <div className="title flex items-center gap-2 mb-3 flex-wrap">
              <img src={Square} alt="Square" />
              <h3 className="fs20 m-0">Delivery address</h3>
            </div>
            <div className="grid grid-cols-12 lg:gap-4 md:gap-2 gap-1">
              <div className="xl:col-span-3 lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12">
                <div className="mb-2">
                  <span className=" block  text-base  text-text-100  font-medium ">
                    Delivery Street
                  </span>
                  <div>
                    <span className="text-gray-500 text-pretty">
                      {data?.customer_delivery_street_address_1}
                    </span>
                  </div>
                </div>
              </div>
              <div className="xl:col-span-3 lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12">
                <div className="mb-2">
                  <span className=" block  text-base  text-text-100  font-medium ">
                    Delivery City
                  </span>
                  <div>
                    <span className="text-gray-500 text-pretty">
                      {data?.customer_delivery_city}
                    </span>
                  </div>
                </div>
              </div>
              <div className="xl:col-span-3 lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12">
                <div className="mb-2">
                  <span className=" block  text-base  text-text-100  font-medium ">
                    Delivery State
                  </span>
                  <div>
                    <span className="text-gray-500 text-pretty">
                      {data?.customer_delivery_state}
                    </span>
                  </div>
                </div>
              </div>
              <div className="xl:col-span-3 lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12">
                <div className="mb-2">
                  <span className=" block  text-base  text-text-100  font-medium ">
                    Delivery Zip Code
                  </span>
                  <div>
                    <span className="text-gray-500">
                      {data?.customer_delivery_postal_code}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <hr className="mb-4" />
            <div className="title flex items-center gap-2 mb-3">
              <img src={Square} alt="Square" />
              <h3 className="fs20 m-0">Product information</h3>
            </div>
            {data?.products?.map((product: any, index: number) => (
              <div
                className="item mb-4 border-l-4 px-2 border-gray-200"
                key={index}
              >
                <h3 className="text-lg mt-0 mb-2 text-primary-100 font-medium">
                  Product {index + 1}
                </h3>
                <div className="grid grid-cols-12 gap-3">
                  <div className="xl:col-span-3 lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12">
                    <div className="mb-2">
                      <span className=" block  text-base  text-text-100 font-medium">
                        Manufacturer
                      </span>
                      <div>
                        <span className="text-gray-500">
                          {data?.products[index]?.manufacturer}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="xl:col-span-3 lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12">
                    <div className="mb-2">
                      <span className=" block  text-base  text-text-100  font-medium ">
                        Model Number
                      </span>
                      <div>
                        <span className="text-gray-500">
                          {data?.products[index].model_number}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="xl:col-span-3 lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12">
                    <div className="mb-2">
                      <span className=" block  text-base  text-text-100  font-medium ">
                        Serial Number
                      </span>
                      <div>
                        <span className="text-gray-500">
                          {data?.products[index].serial_number}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="xl:col-span-3 lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12">
                    <div className="mb-2">
                      <span className=" block  text-base  text-text-100  font-medium ">
                        Product Price
                      </span>
                      <div>
                        <span className="text-gray-500">
                          {data?.products[index].product_retail_price}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="xl:col-span-3 lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12">
                    <div className="mb-2">
                      <span className=" block  text-base  text-text-100  font-medium ">
                        Quantity
                      </span>
                      <div>
                        <span className="text-gray-500">
                          {data?.products[index].quantity}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="regi-form-footer border-t border-border-100 py-5 flex items-center justify-between">
            <Button
              type="submit"
              onClick={backToRegister}
              className={
                "bg-gray-600 flex justify-center items-center px-3 py-4 w-32 rounded-full text-white hover:bg-gray-800 disabled:bg-blue-200 ml-2"
              }
              label={"Back"}
            />
            <Button
              type="submit"
              onClick={() => {
                handleSubmit();
              }}
              loader={loader}
              label={"Submit"}
              className={
                "bg-primary-100 flex justify-center items-center px-3 py-4 rounded-full text-white hover:bg-primary-hover disabled:bg-blue-200 ml-2 mr-2"
              }
              style={{ width: "8rem" }}
            />
          </div>
          <Dialog
            open={showDoneModal}
            onClose={() => setShowDoneModal(true)}
            sx={{
              "& .MuiDialog-paper": {
                backgroundColor: "white",
                position: "absolute",
                top: 10,
              },
            }}
          >
            <DialogContent>
              <button
                className="absolute right-4 top-3 text-black"
                onClick={() => {
                  setShowDoneModal(false);
                  bakeToHome();
                  dispatch(cleanUpValues());
                }}
              >
                <CloseIcon />
              </button>
              <div className="p-6">
                <span className="bg-green-100 rounded-full flex justify-center items-center  w-24 h-24  text-green-500 mx-auto mb-8">
                  <CheckIcon sx={{ width: "50px", height: "50px" }} />
                  {/* <img src={TickMark} alt="something went wrong" /> */}
                </span>
                <p className="font-medium text-xl text-state-success text-center leading-9 0">
                  Thank you for registering you contract.
                  <br /> <b>PROTECTALL™</b> plan!
                </p>
                <Button
                  type="button"
                  onClick={() => {
                    setShowDoneModal(false);
                    bakeToHome();
                    dispatch(cleanUpValues());
                  }}
                  label={"Close"}
                  className="mt-4 bg-brand-primary py-2 px-12 h-12 font-medium text-base  flex justify-center items-center gap-4 rounded-full text-white bg-[#2D6CCA] mx-auto"
                />
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </>
  );
}

export default Step2;
