import React, { useState } from "react";
import InputText from "../InputText";
import { Square, ImageVector } from "../../assets/images";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Button from "../Button";
import { axiosConsumer } from "../../service";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { regiFormData } from "../../store/registration-dataSlice";
import { ArrowForward } from "@mui/icons-material";

const registrationSchema = Yup.object().shape({
  registration_code: Yup.string()
    .required("Registration code is required")
    .matches(/^[a-zA-Z0-9]{8}$/, "Registration code must be of 8 digits"),
});
const RegistrationForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(false);

  const [searchParams] = useSearchParams();
  const registration_code = searchParams.get("rc");

  const getContarct = async (code = registration_code) => {
    setLoader(true);
    try {
      const { data } = await axiosConsumer.get(
        `/pre_sold_contract/registration_code/${code}`
      );
      return data;
    } catch (error: any) {
      if (error?.response?.data?.code === "ERROR_CODE_TOO_MANY_REQUESTS") {
        toast.warn("This code has already been used,kindly try another one", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
          theme: "colored",
        });
      } else if (error?.response?.data?.code === "ERROR_CODE_NOT_FOUND") {
        toast.warning(
          "Unable to find verification code,try to enter another code.",
          {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            theme: "colored",
          }
        );
      } else {
        toast.error("Something went wrong", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
          theme: "colored",
        });
      }
    }
    setLoader(false);
  };

  return (
    <div
      className="bg-no-repeat bg-right -mb-5"
      // style={{
      //   backgroundImage: `url("${ImageVector}")`,
      // }}
    >
      <div className="container mx-auto min-h-[calc(100vh-243px)] p-4 flex items-center ">
        <div className="grid grid-cols-12 gap-x-6 gap-y-4 mb-4 relative z-10 items-center">
          <div className="xl:col-span-6 lg:col-span-6 md:col-span-6 col-span-12">
            <div className="w-full md:w-10/12">
              <img
                src={ImageVector}
                alt="PROTECTALL™ Protection Plan"
                className="md:h-auto h-56 mx-auto md:mb-12 "
              />
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 md:col-span-6 col-span-12">
            <div className="rounded-lg shadow relative bg-white  ml-auto py-8 px-8">
              <Formik
                initialValues={{ registration_code: "" }}
                validationSchema={registrationSchema}
                onSubmit={async (values) => {
                  const contractDetails: any = await getContarct(
                    values.registration_code
                  );
                  dispatch(regiFormData(contractDetails));
                  if (contractDetails) {
                    navigate(
                      `/file-pre-sold-contract?rc=${values.registration_code}`
                    );
                  }
                }}
                enableReinitialize
              >
                {({ handleBlur, handleChange, handleSubmit, values }) => (
                  <Form>
                    <p className="text-2xl leading-tight font-medium mb-6 text-gray-800">
                      Please enter your contract registration code listed on
                      your card.
                    </p>
                    <div className="mb-7 relative">
                      <span className="text-gray-600 block mb-2">
                        Registration Code
                      </span>
                      <InputText
                        name="registration_code"
                        type="text"
                        placeholder="Enter registration code"
                        value={values.registration_code}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage
                        name="registration_code"
                        component="div"
                        className="error text-red-500 text-sm absolute top-full left-0"
                      />
                    </div>
                    <Button
                      type="submit"
                      className="bg-primary-100 flex justify-center  w-full items-center px-4 py-3.5  rounded-lg text-white  hover:bg-primary-hover disabled:bg-blue-200"
                      onClick={() => handleSubmit()}
                      label={"Submit"}
                      loader={loader}
                    />
                  </Form>
                )}
              </Formik>
            </div>
            <div className="text-center">
              <Link
                to={"/contact-us"}
                className="text-primary-100 rounded-lg w-full px-5 shadow py-4 inline-block  mt-4 bg-white transition  hover:text-white hover:bg-primary-100"
              >
                Contact Service
                <ArrowForward className="ml-4" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationForm;
